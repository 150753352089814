import axios from 'axios';
import { getPathApi } from './Utilities';

const getAuthData = () => {
    const user = process.env.REACT_APP_API_USERNAME
    const pass = process.env.REACT_APP_API_PASS

    return {
        username: user,
        password: pass
    }
}

export const getToken = async () => {
    const path = getPathApi()

    try {
        const urlRota = `${path}/AuthAPISite/getToken`;
        const authData = getAuthData();

        const response = await axios.post(urlRota, authData);

        return response;
    } catch (error) {
        console.error('Erro na requisição:', error);
        throw error; // Rejeita a promessa com o erro
    }
}


export const postApi = async (url, token, dados) => {
    try {
        const response = await axios.post(url, new URLSearchParams(dados), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
          },
        });
  
        return response.data
      } catch (error) {
        console.error('Erro ao enviar dados:', error);
        console.log('Detalhes do erro:', error.response.data);
      }
}

export const postApiWithParams = async (url, token, dados) => {
    try {
        const response = await axios.post(url, dados, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
        });
  
        return response.data
      } catch (error) {
        console.error('Erro ao enviar dados:', error);
        console.log('Detalhes do erro:', error.response.data);
      }
}

export const getApiWithParams = async (url, token, dados) => {
    try {
        const response = await axios.get(url, dados, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
        });
  
        return response.data
      } catch (error) {
        console.error('Erro ao enviar dados:', error);
        console.log('Detalhes do erro:', error.response.data);
      }
}

export const getApiSite = async (url, token) => {
    // Configuração da solicitação
    const config = {
        method: 'GET',
        url: url,
        headers: {
            'Authorization': `Bearer ${token}` // Passa o token no cabeçalho de autorização
        }
    };

    return await axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.error('Erro na solicitação:', error);
            return error
        });
}

export const getApiExternal = async (url) => {
    const config = {
        method: 'GET',
        url: url
    }

    return await axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.error('Erro na solicitação:', error);
            return error
        });
}

export const fetchUserToken = async () => {
    try {
        const token = await getToken();

        if (token) {
          // Calcula a data de expiração (24 horas a partir do momento atual)
          const expirationDate = new Date();
          expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000); // 24 horas em milissegundos

          return {
            token: token.data.retorno,
            expires: expirationDate
          }
        } else {
            return {
                token: false,
            }
        }
      } catch (error) {
        console.error('Erro ao obter token:', error);
      }
}

export const fetchUserInfo = async () => {
    const url = `https://api64.ipify.org?format=json`

    const userData = await getApiExternal(url);
    
    if (userData) {
        const ip = btoa(userData.ip);
        const expirationDate = new Date(); // Calcula a data de expiração (24 horas a partir do momento atual)
        expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000); // 24 horas em milissegundos

        return {
            userId: ip,
            expires: expirationDate
        }
    }
}