import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";
import { useCookies } from 'react-cookie'; 
import TagManager from 'react-gtm-module'
import { fetchUserInfo } from "./Functions/APIGdplace";

// Context
import GlobalContext from "./Context/Context";

// Home
import HomeStartupPage from "./Pages/Home/Startup/Startup";
import WhatsAppFloatingBtn from "./Components/WhatsApp";
import { fetchUserToken } from "./Functions/APIGdplace";
const ApplicationPage = lazy(() => import("./Pages/Home/Application"))

// Contact Pages
const ContactUsSimplePage = lazy(() => import("./Pages/Contact/ContactUsSimplePage"))
const DistribuidorContact = lazy(() => import("./Pages/Contact/DistribuidorContact"))

// Additional Pages
const NotFoundPage = lazy(() => import("./Pages/404"))
const MaintenancePage = lazy(() => import("./Pages/AdditionalPages/MaintenancePage"))
const ComingSoonPage = lazy(() => import("./Pages/AdditionalPages/ComingSoonPage"))
const ComingSoonV2Page = lazy(() => import("./Pages/AdditionalPages/ComingSoonV2Page"))
const FaqSPage = lazy(() => import("./Pages/AdditionalPages/FaqSPage"))

// Shop Pages
const ShopWidePage = lazy(() => import("./Pages/Shop/ShopWide"))
const KitRapido = lazy(() => import("./Pages/Shop/KitRapido"))
const Privacy = lazy(() => import("./Pages/Privacy"))
const Cadastro = lazy(() => import("./Pages/Cadastro"))

const BlogStandardPage = lazy(() => import("./Pages/Blogs/BlogStandard"))
const BlogStandardPostPage = lazy(() => import("./Pages/Blogs/PostTypes/BlogFullWidthPost"))

const IntegradorPage = lazy(() => import("./Pages/Integrador"))
const DistribuidorPage = lazy(() => import("./Pages/Distribuidor"))

const Checkout = lazy(() => import("./Pages/Shop/Checkout"))

const CookieConsent = lazy(() => import("./CookieConsent"))

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();
  const [cookies] = useCookies(["cookieConsent"])
  const [tokenApi, setTokenApi, removeTokenApi] = useCookies(["tokenApi"])
  const [userInfo, setUserInfo] = useCookies(["userId"])
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER
  }

  TagManager.initialize(tagManagerArgs)

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  useEffect(() => {
    const fetchData = async () => {
      const tokenUser = await fetchUserToken()

      if(tokenUser.token) {
        setTokenApi("tokenApi", tokenUser.token, { expires: tokenUser.expires, path: '/' });
      } else {
        removeTokenApi("tokenApi");
      }
    }

    if (!tokenApi.tokenApi || tokenApi.tokenApi === undefined) {
      fetchData()
    }
  }, [tokenApi, setTokenApi, removeTokenApi]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userInfo.userId || userInfo.userId === undefined) {
        const userData = await fetchUserInfo();
        
        if (userData) {
          setUserInfo("userId", userData.userId, { expires: userData.expires, path: '/' });
        }
      }
    }

    if (!userInfo.userId || userInfo.userId === undefined) {
      fetchUserData()
    }

  }, [userInfo, setUserInfo]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  {/* GDPlace Routes */}
                  <Route path="/" element={<ApplicationPage style={{ "--base-color": "#2887c5" }} />} />
                  <Route path="/integrador" element={<IntegradorPage style={{ "--base-color": "#2887c5" }} />} />
                  <Route path="/distribuidor" element={<DistribuidorPage style={{ "--base-color": "#2887c5" }} />} />
                  <Route path="/contato" element={<ContactUsSimplePage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/vender-na-gdplace" element={<DistribuidorContact style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/kit-rapido" element={<KitRapido style={{ "background": "#f9f9f9" }} />} />
                  <Route path="/cadastro" element={<Cadastro style={{ "--base-color": "#0038e3" }} />} />

                  <Route path="/blog" element={<BlogStandardPage style={{ "--base-color": "#2887c5" }} />} />
                  <Route path="/blog/:slug" element={<BlogStandardPostPage style={{ "--base-color": "#0038e3" }} />} />

                  <Route path="/politica-privacidade" element={<Privacy style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/checkout" element={<Checkout />} />

                  {/* Additional Pages */}
                  <Route path="/page/error-404" element={<NotFoundPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/maintenance" element={<MaintenancePage />} />
                  <Route path="/page/coming-soon" element={<ComingSoonPage />} />
                  <Route path="/page/coming-soon-V2" element={<ComingSoonV2Page />} />
                  <Route path="/page/faq-s" element={<FaqSPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="*" element={<NotFoundPage />} />
                  
                  <Route path="/testt" element={<HomeStartupPage style={{ "--base-color": "#0038e3" }} />} />
                </Routes>
              </Suspense>
            </AnimatePresence>

            {!cookies.cookieConsent && <CookieConsent />}
            {cookies.cookieConsent && <WhatsAppFloatingBtn />}
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;