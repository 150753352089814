import React, { memo, forwardRef } from 'react'

// Libraries
import { useField } from 'formik';
import AsyncSelect from 'react-select/async'

export const Input = forwardRef(({ label, labelClass, className, showErrorMsg, ...props }, ref) => {
    const [field, meta] = useField(props);

    return (
        <label className={`block relative ${(meta.touched && meta.error) ? "errors-danger" : ""}${labelClass ? ` ${labelClass}` : ""}`}>
            {label}
            <input
                ref={ref} // Encaminhe a ref aqui
                className={`${className}${meta.touched && meta.error ? "errors-danger" : ""}`}
                {...field}
                {...props}
            />
            {meta.touched && meta.error && showErrorMsg ? <span className="text-sm text-error block mt-[5px]">{meta.error}</span> : null}
        </label>
    );
});

export const TextArea = memo(({ label, labelClass, className, showErrorMsg, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <label className={`block relative${(meta.touched && meta.error) ? " errors-danger" : ""}${labelClass ? ` ${labelClass}` : ""}`}>
            {label}
            <textarea className={`${className}${meta.touched && meta.error ? " errors-danger" : ""}`} {...field} {...props} />
            {meta.touched && meta.error && showErrorMsg ? <span className="text-sm text-error block mt-[5px]">{meta.error}</span> : null}
        </label>
    );
});

export const Checkbox = memo(({label, labelClass, className, children, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <label className={`block relative${(meta.touched && meta.error) ? " errors-danger" : ""}${labelClass ? ` ${labelClass}` : ""}`}>
            <input type="checkbox" className={`${className}${meta.touched && meta.error ? " errors-danger" : ""}`} {...field} {...props} />
            {children}
        </label>
    )
})

export const AsyncSelect2 = memo(({ label, labelClass, className, showErrorMsg, ...props }) => {
    const [field, meta] = useField(props)

    const customStyles = {
        control: (styles) => ({
            ...styles,
            color: '#828282',
        }),
        singleValue: (styles) => ({
            ...styles,
            color: '#828282',
        }),
    }      

    return (
        <>
            <label className='font-semibold mb-[8px]' for={label}>{label}</label>
            <AsyncSelect 
                {...field}
                {...props}
                styles={customStyles}
                className={meta.touched && meta.error ? "custom-select2 input-error" : "custom-select2"}
            />
            {meta.touched && meta.error && <div className="ipt-error">{meta.error}</div>}
        </>
    )
});

Input.defaultProps = {
    showErrorMsg: true
}